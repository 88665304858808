import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FocusModeService {
  public focusModeSubject = new BehaviorSubject<boolean>(false);

  public setFocusModeState(isFocusModeActive: boolean): void {
    this.focusModeSubject.next(isFocusModeActive);
  }
}
